<template>
  <div class="auth">
    <div class="auth-background__icon">
      <img src="@/assets/images/icon.svg" alt="background">
    </div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Auth'
}
</script>

<style lang="scss">
.auth{
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #EEF0F8;
  position: relative;
  &-background__icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: #b4e8e4;
    img{
      width: 1000px;
      position: absolute;
      top: 30%;
      left: 27%;
      transform: translate(-50%, -50%);
      filter: blur(40px);
      opacity: 0.6;
    }
  }
}
</style>
